import React from 'react';
import LargeImageOverlapText, {
  LargeImageOverlapTextConfigPropType,
} from 'components/consumer/large-image-overlap-text';

const FeaturedBlog = ({ config }) => {
  if (!config) return null;
  return (
    <div className="[&_img]:min-w-[200px]">
      <LargeImageOverlapText
        lazy={false}
        config={{ ...config, additionalClasses: { heading: 'pl-10' } }}
      />
    </div>
  );
};

FeaturedBlog.propTypes = {
  config: LargeImageOverlapTextConfigPropType,
};

export default React.memo(FeaturedBlog);
