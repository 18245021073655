import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Placeholder from 'components/consumer/Placeholder';
import useBlogHomePage from 'components/consumer/blog/hooks/useBlogHomePage';
import AboveTheFoldServerRenderer from 'components/essentials/AboveTheFoldServerRenderer';

import FeaturedBlog from './components/FeaturedBlog/FeaturedBlog';
import CategoryPost from './components/CategoryPost/CategoryPost';

const BlogHome = ({ blogUrl, slug }) => {
  const { loading, data, error } = useBlogHomePage({ blogUrl });

  if (loading) {
    return <Placeholder type="rect" loading />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const { featuredPostsConfig, categoryPosts } = data || {};

  return (
    <Fragment key={`${slug}blog-home-wrapper`}>
      {featuredPostsConfig && <FeaturedBlog config={featuredPostsConfig} />}
      {categoryPosts?.length > 0 && (
        <div className="pl-5 pr-5 mx-auto my-0 md:pl-[3.333vw] md:pr-[3.333vw]">
          {categoryPosts.map((categoryPost, i) => (
            <AboveTheFoldServerRenderer
              key={categoryPost.slug}
              componentIndex={i + 5}
            >
              <CategoryPost blogUrl={blogUrl} category={categoryPost} />
            </AboveTheFoldServerRenderer>
          ))}
        </div>
      )}
    </Fragment>
  );
};

BlogHome.propTypes = {
  blogUrl: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

export default BlogHome;
