import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import isExternalUrl from 'commons/isExternalUrl';
import normalizeInternalLink from 'commons/normalizeInternalLink';

const linkArrow = 'text-brand relative';
const linkArrowSpan =
  "after:content-['→'] after:ml-1 after:absolute after:transition-[transform] after:duration-[0.1s] after:ease-in-out hover:after:translate-x-[3px]";

const LinkWithArrow = ({ children, arrow, to, ...otherProps }) =>
  !isExternalUrl(to) ? (
    <Link to={normalizeInternalLink(to)} {...otherProps} className={linkArrow}>
      <span className={linkArrowSpan}>{children}</span>
    </Link>
  ) : (
    <a href={to} className={linkArrow} target="_blank" rel="noreferrer">
      <span className={linkArrowSpan}>{children}</span>
    </a>
  );

LinkWithArrow.propTypes = {
  arrow: PropTypes.bool,
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

export default LinkWithArrow;
