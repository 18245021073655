import React from 'react';
import PropTypes from 'prop-types';

import CtaButton from 'components/essentials/Cta/CtaButton';
import BlogCard from '../BlogCard';

const Section = ({ category, posts, onReadAllClick }) => (
  <section className="relative p-10 [border-bottom:1px_solid_#f1f1f1] first:p-8 first:[border-top:1px_solid_#f1f1f1] last:[border-bottom:none] [&_.infinite-scroll-component]:!overflow-hidden">
    <h2 className="text-3xl font-bold mt-0 mx-0 mb-[1em]">{category.text}</h2>
    <div className="flex flex-wrap box-border w-[calc(100%_+_24px)] -m-3">
      {posts.map(post => (
        <div
          key={post.slug}
          className="mt-0 mx-0 mb-4 box-border p-3 flex-grow-0 max-w-full basis-full min-[600px]:max-w-[50%] min-[600px]:basis-1/2"
        >
          <BlogCard post={post} key={post.slug} />
        </div>
      ))}
    </div>
    <div className="mt-10 inline-block lg:mt-[60px] lg:mb-5">
      <CtaButton
        styling="outline-button"
        color="brand"
        block
        onClick={() => onReadAllClick(category.slug)}
      >
        Read All {category.text}
      </CtaButton>
    </div>
  </section>
);

Section.propTypes = {
  category: PropTypes.objectOf(PropTypes.object).isRequired,
  posts: PropTypes.arrayOf(PropTypes.object).isRequired,
  onReadAllClick: PropTypes.func,
};

export default Section;
