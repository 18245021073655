import useSuspenseQuery from 'commons/useSuspenseQuery';
import { GET_BLOG_HOME_PAGE } from 'mocks/queries';

const useBlogHomePage = ({ blogUrl }) => {
  const { loading, data, error } = useSuspenseQuery(GET_BLOG_HOME_PAGE, {
    variables: {
      blogUrl,
    },
  });

  return {
    loading,
    data: data?.getBlogHomePage,
    error: error || data?.getBlogHomePage?.errors?.[0]?.message,
  };
};
export default useBlogHomePage;
