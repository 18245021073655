import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ImageTextBlock from 'components/essentials/ImageTextBlock';

import Image from '../Image/Image';

const BlogCard = ({ post }) => {
  const image = post.featuredImage ? post.featuredImage.fields.file : '';
  const description =
    post.featuredImage.fields?.description !== ''
      ? post.featuredImage.fields?.description
      : post.featuredImage.fields?.title !== ''
      ? post.featuredImage.fields?.title
      : '';

  return (
    <Link to={`${post.slug}/`} className="block">
      <div className="max-h-[370px] overflow-hidden">
        <Image
          className="w-full"
          src={image.url.replace(
            'downloads.ctfassets.net',
            'images.ctfassets.net'
          )}
          alt={description}
          type="grid"
        />
      </div>
      <ImageTextBlock
        heading={{
          content: post.title,
        }}
        subText={{
          content: post.description,
        }}
        cta={{
          linkTo: post.slug,
          content: 'Read more',
        }}
      />
    </Link>
  );
};

export default BlogCard;

BlogCard.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    slug: PropTypes.string,
    featuredImage: PropTypes.shape({
      fields: PropTypes.shape({
        description: PropTypes.string,
        title: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
};
