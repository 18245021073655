import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Section from '../Section/Section';

const CategoryPost = ({ blogUrl, category }) => {
  const history = useHistory();

  const handleReadAllClick = useCallback(
    postSlug => {
      history.push(`/${blogUrl}${postSlug}`);
    },
    [history, blogUrl]
  );

  if (!category) return null;

  return (
    <Section
      category={category}
      posts={category.postsConfig}
      onReadAllClick={postSlug => handleReadAllClick(postSlug)}
      key={category.slug}
    />
  );
};
CategoryPost.propTypes = {
  blogUrl: PropTypes.string,
  category: PropTypes.shape({
    slug: PropTypes.string,
    text: PropTypes.string,
    postsConfig: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        metaDescription: PropTypes.string,
      })
    ),
  }),
};
export default React.memo(CategoryPost);
